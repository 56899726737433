import Decimal from "decimal.js-light";

Decimal.set({

    /**
     * This matches the precision of the IEEE 754 decimal128 type, for no reason other than
     * "this should be plenty". This refers to the number of significant figures in the
     * number, and NOT the number of decimal places (scale).
     */
    precision: 34,

    /**
     * This matches the default IEEE 754 rounding mode, for good reason.
     *
     * @link https://en.wikipedia.org/wiki/Rounding#Round_half_to_even
     */
    rounding: Decimal.ROUND_HALF_EVEN,

    /**
     * Set all other options to their default values.
     */
    defaults: true,
});

export {
    Decimal
}

export default Decimal;
